const en = {
  message: {
    switchLang: "Select Language",
    home: "Home",
    about: "About Us",
    product: "Products",
    productTitle1: "Customized Design Services",
    productTitle2: "Products",
    news: "News",
    contact: "Contact",
    navigation: "Navigator",
    support: "Support",
    // placeholderInNav: "Enter keywords",

    name: "Wisdom Arrives Universe",
    slogan: "Creating and utilising more advanced technologies for better life",
    next: "Next Page",
    up: "Last Page",

    // info1: `"We are a leading provider of IoT and AI solutions."`,
    // info2:
    //   "We use the IoT as the base carrier, combining artificial intelligence algorithms and embedded system development closely with applications. On top of the basic IoT functions, we develop lightweight deep learning algorithms to achieve fast reasoning in the cloud and at the edge, which can be widely used in various intelligent applications, such as industrial IoT, medical and health, enterprise applications, wearable devices, etc. ",

    // whatWeDo1: `Full stack development capability from terminal hardware to Cloud and Mobile apps.`,
    // whatWeDo2: `Customised development for standard and corporate products.`,
    // whatWeDo3: `From circuit design, simulation, PCB Layout, structural design to production delivery and shipment.`,

    // productHead: `Our products `,

    // applicationScenarios: `Product application scenarios`,
  },
  contact: {
    email: "chinali80{'@'}163.com",
    title: `Contact`,
    Postcode: `518101`,
    phone: "13480945504",
    location: `409, Building A, Huafeng SOHO Creative World, Taoyuan Community, Xixiang Street, Bao'an District, Shenzhen, China.`,
    wechat: `WeChat Public`,
    locationTitle: "Address",
    phoneTitle: "Phone",
    PostcodeTitle: "Postcode",
    emailTitle: "Email",
  },
  footer: {
    copyright: "Zhizhenhuanyu Hi-Tech (ShenZhen) Co., Ltd",
    version: "Version",
  },
  about: {
    jianjie: `About Us `,
    bigEventTitle: `Milestones`,
    info: `<p> Zhizhenhuanyu Hi-Tech (Shenzhen) Co., Ltd is a leading provider of intelligent Internet of Things (IoT) products and solutions. The company provides a series of edge processing terminals and edge computing gateway products, such as low-power WAN terminals, cellular mobile communication terminals, short-range wireless communication terminals, and cloud/mobile management software systems, which can be widely used in various industries, such as smart grid, intelligent operation and maintenance, industrial IoT, healthcare, and smart agriculture, etc. </p>
    <p>The company has full stack development capabilities from terminal, edge to cloud, from hardware, firmware to software, providing standard products and customized research and development services. Zhizhenhuanyu adopts the most advanced technology in the industry and are committed to innovation, striving to lead the company's development with core technology. </p>
    <p>Zhizhenhuanyu has an outstanding supply chain, and the key components of its products are provided by industry-leading suppliers with direct technical support. It has a stable supply chain and the ability to deliver projects from research and development to production.</p>
    `,
    bigEvent: `
    <p>Company Milestones:</p>
    <p>1、Exhibit at the 22nd China Hi-Tech Fair from Nov. 11 to 15, 2020.</p>
    <p>2、Exhibited at the 23rd China Hi-Tech Fair from Dec. 27th to 29th, 2021.</p>
    <p>3、On March 9, 2022, Wizhenhuangyu High-Tech (Shenzhen) Co., Ltd. won the tender project of intelligent equipment R&D service for a large domestic electric power enterprise.</p>
    <p>4、On November 15, 2022, we participated in the 24th China Hi-Tech Fair Munich South China Electronics Fair International Embedded System Innovation Forum and delivered a speech: "Smart IoT Solutions, Frontier Technologies and Future Development Trends".</p>`,
  },
  support: {
    title: "Support",
    title2: "Product Brochure",
    table: {
      title: "Title",
      version: "Version",
      publish_time: "Publish Time",
      download: "Download",
      name: "Zhizhenhuanyu Y2024 Product Brochure",
    },
  },
  news: {
    banner: {
      title: "Latest News",
      slogan: "Keep up to date with our latest news",
    },
    publish: "Published on",
  },
  home: {
    section1: {
      title: "Customized Design Services",
      item1: "Customized Electronic Product Design Services",
      item2: "Embedded Software Development",
      item3: "Desktop Application Development",
      item4: "Customized Front-end Development",
      item5: "Data Analysis",
    },
    section2: {
      title: "Products",
      item1: "Gateway Products",
      item2: "Terminal Products",
      item3: "Intelligent IOT Console System",
      item4: "Digital Skyworks-Smart Factory",
    },
    section3: {
      title: "Application Scenarios",
      item1: "Smart Grids",
      item2: "Digitalization",
      item3: "Intelligent Healthcare",
      item4: "Intelligent Factory",
      item5: "Wearable Devices",
    },
  },
  navMenu: {
    support: {
      title: "Support",
      child: {
        title: "Download",
        child: {
          title: "Product Brochure",
        },
      },
    },
    serive: {
      title: "Customized Design Services",
      child1: {
        title: "Electronic Product Design Services",
        child: {
          title1: "Circuit Design",
          title2: "Embedded Software Development",
          title3: "Mechanical Design",
        },
      },
      child2: {
        title: "Application Development",
        child: {
          title1: "Desktop Application Development",
        },
      },
      child3: {
        title: "Customized Front-end Development",
        child: {
          title1: "Front-end Development",
        },
      },
      child4: {
        title: "Data Analysis",
      },
      child5: {
        title: "Edge Computing Gateway",
      },
    },
    product: {
      title: "Products",
      child1: {
        title: "EPT Series",
        child: {
          title1: "Mini Embedded AI Coreboard",
          title2: "Small IoT Terminal Board",
          title3: "4G Terminal",
          title4: "NB-IoT Terminal",
          title5: "LoRa Terminal",
          title6: '"Opportunity" IoT terminal core boards',
          title7: "LoRa-4G Gateway",
          title8: "TurMass™Terminal",
          title9: "TurMass™-4G Gateway",
          title10: "LoRa-Ethernet Gateway",
        },
      },
      child2: {
        title: "Cloud Software",
        child: {
          title1: "Intelligent IOT Console System",
        },
      },
      child3: {
        title: "Digtal Twins",
        child: {
          title1: "Digital Skyworks-Smart Factory",
        },
      },
      child4: {
        title: "Machine Vision",
        child: {
          title1: "Security Applications",
          title2: "Industrial Inspection",
        },
      },
    },
  },
  download: "Download literature",

  banner: {
    ele: {
      title: "One-Stop Customized Electronic Product Design Service(EDS)",
      desc: "Complete Product Design {'|'} Parts Design {'|'} Engineering Changes",
    },
    app: {
      title: "Application Development",
    },
    frontend: {
      title: "Front-end Development",
    },
    software: {
      title: "Cloud Software",
    },
    digtal: {
      title: "Digtal Twins",
    },
    hardware: {
      title: "EPT Series",
      core: {
        input: "input",
        size: "Module size",
        Core: "Core",
        FLASH: "FLASH",
        RAM: "RAM/SRAM",
        Fre: "Main Frequency(Max)",
      },
      netpune: {
        input: "Input Parameters",
        chip: "Module ",
        SRAM: "SRAM | ROM",
        FLASH: "SPI FLASH",
        device1: "Temperature and humidity sensor",
        device2: "Six-axis acceleration",
      },
      fourG: {
        input: "Power Supply",
        size: "Size",
        RAM: "RAM",
        ROM: "ROM",
        file: "File system",
      },
      nbiot: {
        input: "Power Supply",
        fre: "Frequency band",
        size: "Size",
        core: "Core",
        flash: "FLASH",
        RAM: "RAM | SRAM",
        max: "Max Frequency",
        eeprom: "EEPROM",
      },
      lora: {
        input: "Power Supply",
        size: "Size",
        core: "Core",
        flash: "FLASH",
        RAM: "RAM | SRAM",
        max: "Max Frequency",
        eeprom: "EEPROM",
      },
      gateway: {
        flash: "FLASH",
        fre: "LoRa frequency",
      },
    },
    vision: {
      title: "Machine Vision",
    },
    data: {
      title: "Data Analysis",
    },
  },
  linkTab: {
    ele: {
      title: "Electronic Product Design Services",
    },
  },
  input: "power supply",
  size: "size",
  download: "download",
  fre: "frequency",
  core: "core",
  netrate: "Ethernet speed",
  rate: "Transmission rate",
  distance: "Communication distance",
  fre_lora: "LoRa Frequency band",
  fre_4g: "4G Frequency band",
  LoRa_Receive_Sensitivity: "LoRa_Receive_Sensitivity",
  max_freq: "Main Frequency",
};

export default en;
